<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <form class="row" @submit="submitForm" v-if="!isLoading">
            <div class="col-12 col-md-8">
                <div class="card">
                    <div class="card-header d-flex justify-content-between flex-wrap">
                        <h5 v-if="isEdit == 0">
                            {{ $t('project.header.create') }}
                        </h5>
                        <h5 v-if="isEdit == 1">{{ $t('project.header.edit') }} {{ header.project_name }}</h5>
                        <div v-if="isEdit == 1">
                            <delete_confirm :text="lang('project.delete.body')" :url="projectDeleteUrl()"
                                @confirm="onDelete" @error="onDelete"></delete_confirm>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group row mb-3">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('project.project_name') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': validation.project_name != '' }" v-model="item.project_name">
                                <div class="invalid-feedback">{{ validation.project_name }}</div>
                            </div>
                        </div>
                        <div class="form-group row mb-3">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('project.project_shortname') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': validation.project_shortname != '' }"
                                    v-model="item.project_shortname">
                                <div class="invalid-feedback">{{ validation.project_shortname }}</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label class="form-label">
                                {{ $t('project.project_description') }}</label>
                            <editor :init="vtinymce" v-model="item.project_description" rows="15"
                                :class="{ 'is-invalid': validation.project_description != '' }"></editor>
                            <div class="invalid-feedback">{{ validation.project_description }}</div>
                        </div>
                        <div class="form-group row mb-3">
                            <div class="col-12 col-md-3">
                                <label class="form-label">
                                    {{ $t('project.client_id') }}</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <client_autocomplete v-model="item.client_id"></client_autocomplete>
                                <div :class="{ 'd-block': validation.client_id != '' }" class="invalid-feedback">
                                    {{ validation.client_id }}</div>
                            </div>
                        </div>

                        <div class="text-center">
                            <button :disabled="lockSave" type="submit" @click.prevent="submitForm"
                                class="btn btn-outline-success me-2">
                                {{ $t('project.button.save') }}</button>
                            <button type="button" @click.prevent="cancelForm" class="btn btn-outline-secondary">
                                {{ $t('project.button.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <h5 class="f-cap">
                            {{ $t('project.header.settings') }}
                        </h5>
                        <div class="card-header-right">
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label class="form-label">
                                {{ $t('project.project_status') }}</label>
                            <select class="form-select" v-model="item.project_status"
                                :class="{ 'is-invalid': validation.project_status != '' }">
                                <option value=""></option>
                                <option value="enabled">Aktywny</option>
                                <option value="disabled">Zakończony</option>
                            </select>

                            <div class="invalid-feedback">{{ validation.project_status }}</div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="f-cap">{{ $t('project.header.workstage') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2">
                            <label class="f-cap mb-2">
                                {{ $t('project.workstage.add') }}</label>
                            <div class="input-group">
                                <input class="form-control" v-model="workstage_name" type="text">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" @click="addWorkstage()">+</button>
                                </div>
                            </div>
                        </div>
                        <div is='draggable' v-model="item.workstages" handle=".drag">
                            <div class="form-group d-flex justify-content-between align-items-center flex-wrap mb-2"
                                v-for="workstage, index in item.workstages" :key="index">
                                <template v-if="!workstage.delete || workstage.delete == 0">
                                    <div class=" d-flex gap-2 align-items-center">
                                        <span class="drag btn btn-outline-secondary btn-icon-sm"><span
                                                class="material-icons-outlined">open_with</span></span>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :id="'switch-a3-' + index"
                                                v-model="workstage.workstage_status" value="1">
                                            <label :for="'switch-a3-' + index" class="form-check-label"></label>
                                            <label :for="'switch-a3-' + index"> {{ workstage.workstage_name }}</label>
                                        </div>
                                    </div>
                                    <div class=" -1 px-0"><button type="button"
                                            class="btn btn-icon-sm btn-outline-secondary float-right"
                                            @click.prevent="workstage.delete = 1"><i
                                                class="material-icons-outlined">delete</i></button></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h5 class="f-cap">
                            {{ $t('project.header.team') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group mb-2">
                            <label class="f-cap mb-1">
                                {{ $t('project.team.adduser') }}</label>
                            <select class="form-control" @change="onChangeUser()" v-if="users" v-model="users_select_model">
                                <option value="0" disabled>
                                    Wybierz użytkownika aby go dodać
                                </option>
                                <option :value="u" v-for="u, index in $store.state.slownik_user" :key="index">
                                    {{ u.username }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group d-flex justify-content-between align-items-center flex-wrap mb-2"
                            v-for="user, index in item.users" :key="index">
                            <template v-if="!user.delete || user.delete == 0">
                                <MagicoAvatar class="me-2" :user_id="user.id"></MagicoAvatar>
                                
                                <div class="flex-fill"><span>{{ getUserName(user.user_id) }}</span></div>
                                <!-- <div class="col-10 col-xl-4 text-right">
                                    <select v-model="user.user_position" class="form-control">
                                        <option :value="u.value" v-for="u, index2 in slownik_user_position" :key="index2">
                                            {{ u.name }}
                                        </option>
                                    </select>
                                </div> -->
                                <div class=" px-0"><button type="button"
                                        class="btn btn-icon-sm p-2 btn-outline-secondary float-right"
                                        @click.prevent="user.delete = 1"><i
                                            class="material-icons-outlined">delete</i></button>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>
<script>
import tinymceConfig from '../../components/tinimce/config.js'
import Editor from '@tinymce/tinymce-vue'
import axios from '../../api/axios';
import DeleteConfirm from '../../components/DeleteConfirm.vue';
import draggable from 'vuedraggable'
import ClientAutocomplete from '../../components/client/ClientAutocomplete.vue';
import api from '../../api/api';
import MagicoAvatar from '../../components/MagicoAvatar.vue';
export default {
    name: 'ProjectEdit',
    components: {
    "editor": Editor,
    "delete_confirm": DeleteConfirm,
    "draggable": draggable,
    "client_autocomplete": ClientAutocomplete,
    MagicoAvatar
},
    props: ['module'],
    computed: {
        slownik_user: function () { return this.$store.state.slownik_user },
        slownik_contest: function () { return this.$store.state.slownik_contest },
        slownik_client: function () { return this.$store.state.slownik_client },
        slownik_user_position: function () { return this.$store.state.slownik_user_position },
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            vtinymce: tinymceConfig,
            dropzoneOptions: {
                url: false
            },

            isLoading: true,
            lockSave: false,
            id: 0,
            isEdit: false,
            users: [],
            users_select_model: {},
            item: {
                project_name: '',
                project_shortname: '',
                project_status: '',
                project_description: '',
                client_id: '',
                contest_id: 0,
                users: [],
                project_type: 'standard',
                workstages: [],
                project_module: 'project',
                settlementstages: [],

            },
            header: {
                project_name: '',
            },
            reloadFiles: false,
            uploadFileName: '',
            validation: {
                project_name: '',
                project_shortname: '',
                project_description: '',
                project_status: '',
                client_id: '',
                contest_id: '',
                project_type: '',
            },
            workstage_name: '',
            settlementstage_name: '',
        }
    },
    methods: {
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar;
        },
        getUserName(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.username;
        },
        lang(x) { return this.$t(x) },
        onDelete() {
            this.$router.push('/project/');
        },
        projectDeleteUrl() {
            return this.BASE_URL + 'api/v1/project/' + this.id
        },
        addWorkstage() {
            if (this.workstage_name.length > 1) {
                this.item.workstages.push({ workstage_id: 0, workstage_name: this.workstage_name, 'delete': 0, workstage_position: this.item.workstages.length + 1 });
                this.workstage_name = '';
            }
        },
        addsettlementstage() {
            if (this.settlementstage_name.length > 1) {
                this.item.settlementstages.push({ settlementstage_id: 0, settlementstage_name: this.settlementstage_name, 'delete': 0, settlementstage_position: this.item.settlementstages.length + 1 });
                this.settlementstage_name = '';
            }
        },
        select2Options(item) {
            console.log(item);
            return [{ id: Number(item.client_id), text: 'Klient name' }];
        },
        //users
        onChangeUser: function () {
            var jest = false;
            if (this.users_select_model == 0) return;
            //undelete
            this.item.users.forEach(user => {
                if (user.id == this.users_select_model.id) {
                    user.delete = 0;
                }
            })
            this.item.users.forEach(user => {
                if (user.id == this.users_select_model.id) {
                    jest = true
                }
            })
            if (!jest) {
                this.item.users.push({
                    delete: 0,
                    username: this.users_select_model.username,
                    projectuser_id: 0,
                    user_id: this.users_select_model.user_id,
                    id: this.users_select_model.user_id,
                    avatar: this.users_select_model.avatar,
                })
            }
            this.users_select_model = 0;
        },
        //koniec users
        cancelForm: function () {
            this.$router.push('/project/' + this.id + '/task');
        },
        submitForm: function () {
            for (const property in this.validation) {
                this.validation[property] = '';
            }
            for (const tablica in this.representative_validation) {
                for (const property in this.representative_validation[tablica]) {
                    this.representative_validation[tablica][property] = '';
                }
            }
            if (this.isEdit > 0) {
                this.updatePost();
            } else {
                this.createPost();
            }
            console.log(this.item);
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/project/' + this.id).then(response => {
                //stworzenie pustych walidacji
                console.log(response.data.representative);
                if (response.data.settlementstages) {
                    response.data.settlementstages.forEach(item => {
                        item.delete = 0
                        item.settlementstage_status = (item.settlementstage_status == 1) ? true : false;
                    })
                } else {
                    response.data.settlementstages = [];
                }

                if (response.data.workstages) {
                    response.data.workstages.forEach(item => {
                        item.delete = 0
                        item.workstage_status = (item.workstage_status == 1) ? true : false;
                    })
                } else {
                    response.data.users = [];
                }
                if (response.data.users) {
                    response.data.users.forEach(item => {
                        item.delete = 0
                    })
                } else {
                    response.data.users = [];
                }
                vm.item = response.data;
                vm.header.project_name = vm.item.project_name;
                vm.isLoading = false;
                if (vm.item['_links'].upload) {
                    vm.dropzoneOptions.url = vm.item['_links'].upload.href;
                }
            });
        },
        cleanUsers: function () {
            var table = [];
            this.item.users.forEach(item => {
                console.log(item)
                if (!item.delete || item.delete == 0) {
                    table.push(item);
                }
            })
            return table;
        },
        cleanWorkstages: function () {
            var table = [];

            this.item.workstages.forEach((item, index) => {
                item.workstage_position = index;

                console.log(item)
                if (!item.delete || item.delete == 0) {
                    table.push(item);
                }
            })
            return table;
        },
        cleansettlementstages: function () {
            var table = [];

            this.item.settlementstages.forEach((item, index) => {
                item.settlementstage_position = index;

                console.log(item)
                if (!item.delete || item.delete == 0) {
                    table.push(item);
                }
            })
            return table;
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        createPost: function () {
            var vm = this;
            this.item.users = this.cleanUsers();
            this.item.workstages = this.cleanWorkstages();
            this.item.settlementstages = this.cleansettlementstages();
            axios.post(this.BASE_URL + 'api/v1/project', this.item).then(response => {
                //MAGICO_PANEL.alert('success', 'projekt utworzony');
                this.$router.push('/project/' + response.data.project_id + '/task');
            }).catch(error => {
                vm.handleAxiosError(error);
            });
        },
        updatePost: function () {
            this.item.users = this.cleanUsers();
            this.item.workstages = this.cleanWorkstages();
            this.item.settlementstages = this.cleansettlementstages();
            axios.put(this.BASE_URL + 'api/v1/project/' + this.id, this.item).then(() => {
                //MAGICO_PANEL.alert('success', 'projecktzaktualizowany');
                this.$router.push('/project/' + this.id + '/task');
            }).catch(this.handleAxiosError);
        },
        sendingEvent: function () {
            this.lockSave = true;
        },
        uploadSuccess: function () {
            //MAGICO_PANEL.alert('success', 'pliki wgrane prawidłowo');
            this.lockSave = false;
            this.reloadFiles = true;

            this.uploadFileName = '';

        },
    },
    created() {
        console.log('tinimce', this.vtinymce);
        // this.search.project_module = this.module;
        this.item.project_module = this.module ? this.module : 'project'
        this.id = this.$route.params.id;
        this.isEdit = (this.id > 0) ? 1 : 0;
        if (this.isEdit) {
            this.getPost()
        } else {
            this.isLoading = false

        }
    }
}
</script>