<template>
    <multiselect @input="onchange" v-model="selected" :options="options" track-by="client_id" label="client_name">
    </multiselect>
</template>
<script>
import axios from '../../api/axios';
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
export default {
    components: { Multiselect },
    data() {
        return {
            selected: null,
            options: []
        }
    },
    created() {
        this.getPosts();
    },
    methods: {
        onchange(value) {
            this.$emit('input', value.client_id)
        },
        getPosts() {
            axios.get('/api/v1/client').then(response => {
                console.log(response.data.data)
                this.options = response.data.data

            })
        }
    },
}

</script>